import React from 'react';
import styled from 'styled-components';
import { color, pageMargins, typography } from '../../shared/styles.js';

const StyledImageTextContainer = styled.section`
  background-color: ${({ page }) => (page ? 'white' : color.mainBg)};
  width: 100%;
  ${pageMargins}
  padding-top: ${({ page }) => (page ? '11.375rem !important' : '4.125rem !important')};
  padding-bottom: 4.125rem !important;
  & .img-text__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  & .project-img-text__img {
    width: 55%;
  }
  & .project-img-text__text {
    float: right;
    width: 35%;
    & p {
      line-height: 1.75rem;
      font-size: ${typography.size.m1};
    }
  }
  @media only screen and (max-width: 1024px) {
    & .img-text__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .project-img-text__img {
      width: 100%;
    }
    & .project-img-text__text {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const ImageText = ({ imgUrl, text, page }) => {
  return (
    <StyledImageTextContainer page={page}>
      <div className="img-text__container">
        <img className="project-img-text__img" src={`${imgUrl}&w=1024`} alt="" />
        <div className="project-img-text__text">
          <p>{text}</p>
        </div>
      </div>
    </StyledImageTextContainer>
  );
};

export default ImageText;
